import React, { useRef, useEffect } from 'react'
// import Spinner from './Spinner'
import videojs from 'video.js'
import 'video.js/dist/video-js.css'
import 'videojs-contrib-quality-levels'
import 'videojs-hls-quality-selector'
import VideoThumbnail from './assets/images/video_thumb2.jpg'
import 'videojs-landscape-fullscreen'

const Player = ({ url }) => {

    const videoRef = useRef(null)
    const playerRef = useRef(null)


    useEffect(() => {

        const player = playerRef.current

        if (!player) {

            const videoElement = videoRef.current

            if (!videoElement) {
                return
            }

            const videoJsOptions = {
                controls: true,
                sources: [
                    {
                        src: url,
                        type: 'application/x-mpegURL'
                    }
                ],
                controlBar: {
                    pictureInPictureToggle: false
                }
            }

            playerRef.current = videojs(videoElement, videoJsOptions)

            playerRef.current.landscapeFullscreen({
                fullscreen: {
                    enterOnRotate: true,
                    exitOnRotate: true,
                    alwaysInLandscapeMode: true,
                }
            })
        }

        return () => {
            if (player) {
                player.dispose()
                playerRef.current = null
            }
        }

    }, [url])

    return (
        <>
            {
                <div className='video-container'>
                    <video

                        ref={videoRef}
                        poster={VideoThumbnail}
                        className={`video-js vjs-big-play-centered vjs-theme-${'forest'} vjs-texttrack-settings`}
                    />
                </div>
            }
        </>
    )
}

export default Player
