import React from 'react'
import VideoJS from './Video'
import VusportLogo from './assets/images/big_logo.svg'
import GoogleDownload from './assets/images/google.svg'
import AppleDownload from './assets/images/apple.svg'
import VideoThumbnail from './assets/images/video_thumb.png'
import StreamingIcon from './assets/images/streaming_icon.svg'
import OriginalIcon from './assets/images/originals.svg'
import AnalyticsIcon from './assets/images/analytics.svg'
// import DownloadApp from './assets/webp/section-image.webp'
import DownloadApp from './assets/webp/640x676.webp'
import GoogleDownloadDark from './assets/images/dark_google.svg'
import AppleDownloadDark from './assets/images/dark_apple.svg'
import SSSGLogo from './assets/images/sssg_logo.svg'
import TeleGramIcon from './assets/images/telegram.png'
import FacebookIcon from './assets/images/facebook.png'
import TwitterIcon from './assets/images/twitter.png'
import YoutubeIcon from './assets/images/youtube.png'
import InstagramIcon from './assets/images/instagram.png'
import LinkedinIcon from './assets/images/linkedin.png'
// import MobileScreens from './assets/webp/vusport_experience.webp'
import MobileScreens from './assets/webp/525x595.webp'
import 'video.js/dist/video-js.css'
import Player from './Player'

function App() {

  return (
    <div className='appMain'>

      <div
        className='hero-image'
      >
        <div
          className='container-1'
        >
          <div
            className='vusport-logo-container'
          >
            <img
              src={VusportLogo}
              className='vusport-logo'
            />
            <div
              className='font-face-rocklin hero-image-title'
            >
              <span>Experience</span>
              <span className='hero-image-title-2'>Sports</span>
            </div>
            <div
              className='font-face-rocklin hero-image-subtitle'
            >
              Like never before
            </div>
          </div>

          <div
            className='hero-image-download'
          >

            <div
              className='font-face-rocklin download-app-text'
            >
              Download our app now
            </div>

            <div style={{ display: 'flex', gap: '1rem' }}>

              <div>
                <a href='https://play.google.com/store/apps/details?id=com.vusport.fantasy.sports.streaming' target='_blank' rel='noreferrer'>
                  <img
                    src={GoogleDownload}
                    className='play-store-image'
                  />
                </a>
              </div>

              <div>
                <a href='https://apps.apple.com/in/app/vusport-live-cricket-stats/id6444726430' target='_blank' rel='noreferrer'>
                  <img
                    src={AppleDownload}
                    className='app-store-image'
                  />
                </a>
              </div>

            </div>
          </div>
        </div>
      </div>

      <div
        className='feature-container'
      >

        {/* <svg className='blue-svg-mobile' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 60" >
          <path id="banner-shape-4" d="M360,48.571H0V7.718c29.482-30.957,95.222,32.1,114.666,0s90.431,33.242,115.123,0S330.1,55.544,360,7.718Z" transform="translate(0 1.429)" fill="#FBF1E9" />
        </svg>

        <svg className='blue-svg-web' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 20">
          <path id="banner-shape-4" d="M360,48.571H0V7.718c29.482-30.957,95.222,32.1,114.666,0s90.431,33.242,115.123,0S330.1,55.544,360,7.718Z" transform="translate(0 1.429)" fill="#FBF1E9" />
        </svg> */}


        <svg className='blue-svg-mobile' xmlns="http://www.w3.org/2000/svg" width="100%" height="89" viewBox="0 0 1440 89">
          <path id="background_shape" d="M1440,87.571H0V7.719c117.928-30.959,380.889,32.1,458.666,0s361.725,33.244,460.494,0,401.23,47.829,520.84,0Z" transform="translate(0 1.429)" fill="#fbf1e9" />
        </svg>
        <svg className='blue-svg-web' xmlns="http://www.w3.org/2000/svg" width="100%" height="160" viewBox="0 0 1440 89">
          <path id="background_shape" d="M1440,87.571H0V7.719c117.928-30.959,380.889,32.1,458.666,0s361.725,33.244,460.494,0,401.23,47.829,520.84,0Z" transform="translate(0 1.429)" fill="#fbf1e9" />
        </svg>

        <div style={{ width: "100%" }}>
          <div
            className='video-title'
          >
            Bringing Sports Analytics, Streaming & Content to your fingertips!
          </div>
        </div>
        <div
          className='video-subtitle'
        >
          Delivering joy to hard-core sports fan through technology.
        </div>

        <Player url={"https://d1bex21weu7xcw.cloudfront.net/4687/master.m3u8"} />

        <div
          className='section-2'
        >

          <img
            src={MobileScreens}
            className='mobile-screens'
          />

          <div
            className='feature-details-container'
          >

            <div>
              <div
                className='feature-details-title'
              >
                THE VUSPORT Experience!
              </div>

              <div
                className='feature-details-subtitle'
              >
                One-stop shop for a Sports Fan.
              </div>
            </div>

            <div>
              <div
                className='single-feature-container'
              >

                <div
                  className='streaming-icon'
                >
                  <img
                    src={StreamingIcon}
                  />
                </div>

                <div
                  className='single-feature-text-container'
                >
                  <div
                    className='single-feature-title'
                  >
                    Sports Streaming
                  </div>
                  <div
                    className='single-feature-subtitle'
                  >
                    Cricket & Football matches across the world streamed lived to your mobile devices.
                  </div>
                </div>

              </div>
              <div
                className='single-feature-container'
              >

                <div
                  className='feature-icon'
                >
                  <img src={OriginalIcon} />
                </div>

                <div
                  className='single-feature-text-container'
                >
                  <div
                    className='single-feature-title'
                  >
                    Original Content
                  </div>
                  <div
                    className='single-feature-subtitle'
                  >
                    Exciting and entertaining content related to your favourite matches.
                  </div>
                </div>

              </div>
              <div
                className='single-feature-container'
              >

                <div
                  className='feature-icon'
                >
                  <img src={AnalyticsIcon} />
                </div>

                <div
                  className='single-feature-text-container'
                >
                  <div
                    className='single-feature-title'
                  >
                    Sports Analytics
                  </div>
                  <div
                    className='single-feature-subtitle'
                  >
                    Data-backed insights and predictions to help you win big.
                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>

        <div
          className='download-app-container'
        >
          <div
            className='download-details-text download-details-text-web'
          >

            <div>Come join us in India’s biggest sporting revolution.</div>

            <div
              className='download-details-paragraph'
            >
              Download now and consume sports like never before!
            </div>

            <div
              className='download-details-icons'
            >
              <a href='https://play.google.com/store/apps/details?id=com.vusport.fantasy.sports.streaming' target='_blank' rel='noreferrer'>
                <img
                  src={GoogleDownloadDark}
                  className='play-store-image'
                />
              </a>
              <a href='https://apps.apple.com/in/app/vusport-live-cricket-stats/id6444726430' target='_blank' rel='noreferrer'>
                <img
                  src={AppleDownloadDark}
                  className='app-store-image margin-left-10'
                />
              </a>
            </div>

          </div>

          <div
            className='app-screenshot-container'
          >
            <img
              src={DownloadApp}
              className='app-screenshot'
            />
          </div>

          <div
            className='download-details-text download-details-text-mobile'
          >

            <div>Come join us in India’s biggest sporting revolution.</div>

            <div
              className='download-details-paragraph'
              style={{
                width: '80%',
                margin: 'auto'
              }}
            >
              <span>Get endless entertainment of sports you love. Feel the sports Experience like never before!</span>
            </div>

            <div
              className='download-details-icons'
            >
              <a href='https://play.google.com/store/apps/details?id=com.vusport.fantasy.sports.streaming' target='_blank' rel='noreferrer'>
                <img
                  src={GoogleDownloadDark}
                  className='play-store-image'
                />
              </a>
              <a href='https://apps.apple.com/in/app/vusport-live-cricket-stats/id6444726430' target='_blank' rel='noreferrer'>
                <img
                  src={AppleDownloadDark}
                  className='app-store-image margin-left-10'
                />
              </a>
            </div>

          </div>


        </div>

      </div>

      {/* <svg className='orange-svg-mobile' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 360 50">
        <path id="banner-shape-4" d="M360,48.571H0V7.718c29.482-30.957,95.222,32.1,114.666,0s90.431,33.242,115.123,0S330.1,55.544,360,7.718Z" transform="translate(0 1.429)" fill="#fff" />
      </svg> */}

      {/* <svg className='orange-svg-web' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 20">
        <path id="banner-shape-4" d="M360,48.571H0V7.718c29.482-30.957,95.222,32.1,114.666,0s90.431,33.242,115.123,0S330.1,55.544,360,7.718Z" transform="translate(0 1.429)" fill="#fff" />
      </svg> */}


      <svg className='orange-svg-web' xmlns="http://www.w3.org/2000/svg" width="100%" height="214" viewBox="0 0 1440 214">
        <path id="bottom_artwork" d="M1440,212.571H0V7.718c117.928-30.957,380.889,32.1,458.666,0s361.725,33.242,460.494,0,401.23,47.826,520.84,0Z" transform="translate(0 1.429)" fill="#fff" />
      </svg>
      <svg className='orange-svg-mobile' xmlns="http://www.w3.org/2000/svg" width="100%" height="214" viewBox="0 0 1440 214">
        <path id="bottom_artwork" d="M1440,212.571H0V7.718c117.928-30.957,380.889,32.1,458.666,0s361.725,33.242,460.494,0,401.23,47.826,520.84,0Z" transform="translate(0 1.429)" fill="#fff" />
      </svg>


      <div
        className='footer'
      >

        <div>
          <div
            className='sssg-logo-text'
          >
            a brand of
          </div>

          <div
            className='d-flex justify-content-center py-5'
          >
            <a href='https://supersixsports.com/' target='_blank' rel='noreferrer'>
              <img
                src={SSSGLogo}
                className='sssg-logo'
              />
            </a>
          </div>
        </div>

        <div
          className='social-icons-container'
        >

          <a href='https://www.youtube.com/c/vusportstreaming' target='_blank' rel='noreferrer'>
            <img
              src={YoutubeIcon}
              className='social-icon'
            />
          </a>

          <a href='https://twitter.com/VUSportOfficial' target='_blank' rel='noreferrer'>
            <img
              src={TwitterIcon}
              className='social-icon'
            />
          </a>

          <a href='https://t.me/vusportofficial' target='_blank' rel='noreferrer'>
            <img
              src={TeleGramIcon}
              className='social-icon'
            />
          </a>

          <a href='https://www.instagram.com/vusportofficial/?hl=en' target='_blank' rel='noreferrer'>
            <img
              src={InstagramIcon}
              className='social-icon'
            />
          </a>
          <a href='https://m.facebook.com/profile.php?id=100078072909784' target='_blank' rel='noreferrer'>
            <img
              src={FacebookIcon}
              className='social-icon'
            />
          </a>


          <a href='https://www.linkedin.com/company/vusport/mycompany/' target='_blank' rel='noreferrer'>
            <img
              src={LinkedinIcon}
              className='social-icon'
            />
          </a>

        </div>

        <div
          className='copyright-text'
        >
          Copyright ©2023 VUsport. All rights reserved.
        </div>
      </div>

    </div>
  )

}

export default App
